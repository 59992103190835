import React from "react";
import "./Blog.css";

const Blog = () => {
  return <div className="blog">
    <h1>
        Keep checking this space for Helpful Blogs around Domains.
    </h1>
  </div>;
};

export default Blog;
