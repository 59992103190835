import React from 'react'
import Hero from '../Hero/Hero';

const Homepage = () => {
  return (
    <div>
        <Hero/>
    </div>
  )
}

export default Homepage;