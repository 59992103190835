import React from "react";
import "./About.css";

const About = () => {
  return (
    <div className="about">
      <div className="aboutimage">
        <img
          src="/images/richard.png"
          alt="business owner named Richard Morris"
        ></img>
      </div>
      <div>
        <h4>My Mantra - "Make Something Happen"</h4>
        <br></br>
        <p>
          Throughout my entrepreneurial journey, I've ventured into various
          industries, from medical billing and debt collection to investigations
          and specialty tax services, the latter of which I still own and
          manage. More recently I've launched ventures like{" "}
          <a href="https://www.prestonames.com/">PrestoNames.com</a>, Domain
          Gourmet™, Saving Minds, LLC, and Pier12.com. I've acquired over 1,500
          domain names, including MakeSomethingHappen.com,
          <a href="https://www.squadhelp.com/name/USUnited"> UsUnited.com,</a>
          Webenomics.com and{" "}
          <a href="https://domains.squadhelp.com/lpd/name/ganjaxpress.com">
            GanjaXpress.com.
          </a>{" "}
          You can explore my domain portfolio at PrestoNames.com (available
          January 2024) or refer to my Linkedin bio,
          https://www.linkedin.com/in/richard-morris-227a6918/ If any of these
          names pique your interest, feel free to reach out{" "}
          <a href="mailto:richard@makesomethinghappen.com">here</a> and I'll
          gladly assist you.
        </p>
      </div>
      <h4>Meet Biscuit &#128021; - Presto Names Mascot</h4>
      <br></br>
      <img
        className="mascot"
        src="/images/biscuit.png"
        alt="Presto Names Mascot"
      ></img>
    </div>
  );
};

export default About;
