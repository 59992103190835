import React, { useState } from "react";
import "./Header.css";
import { Link } from "react-router-dom";

const Header = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="header">
      <div>
        <h1>
          <Link to="/">PrestoNames</Link>
        </h1>
      </div>
      <button className="collapse-button" onClick={toggleCollapse}>
        {isCollapsed ? (
          <span className="material-symbols-outlined">close</span>
        ) : (
          <span className="material-symbols-outlined">menu</span>
        )}
      </button>
      <div className={`headeroptions ${isCollapsed ? "collapsed" : ""}`}>
        <h2>
          <Link to="/">Home</Link>
        </h2>
        <h2 style={{ color: "#ff6868" }}>
          <Link to="/blog">Blog</Link>
        </h2>
        <h2>
          <Link to="/contact">Contact</Link>
        </h2>
        <h2>
          <Link to="/about">About</Link>
        </h2>
      </div>
    </div>
  );
};

export default Header;
