import React, { useState, useEffect } from "react";
import "./Contact.css";

const Contact = () => {
  const [localTime, setLocalTime] = useState("");

  useEffect(() => {
    const getTime = () => {
      const now = new Date();
      const options = {
        timeZone: "America/New_York",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: true,
      };
      const formattedTime = now.toLocaleString("en-US", options);
      setLocalTime(formattedTime);
    };

    getTime();
    // Update time every second
    const interval = setInterval(getTime, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="contact">
      <h2>For a Faster Domain Buying Experience</h2>
      <br></br>
      <h3>
        Feel free to reach me at{" "}
        <a href="mailto:richard@makesomethinghappen.com">
          richard@makesomethinghappen.com
        </a>
        <br />
        <br></br>
        Local Time: {localTime}
      </h3>
    </div>
  );
};

export default Contact;
