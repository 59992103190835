import React from "react";
import "./Collection.css";

const Collection = () => {
  const domainNames = [
    "aborthate.com",
    "abortsoros.com",
    "acerair.com",
    "acerdigital.com",
    "acerdomains.com",
    "acertravel.com",
    "achievedomains.com",
    "ahigrill.com",
    "ahiheaven.com",
    "ahiseafood.com",
    "americascourse.com",
    "asthmador.com",
    "bethetiger.com",
    "bidenwaffle.com",
    "bigtowne.com",
    "billiondollarniche.com",
    "billiondollarvisionary.com",
    "biteofmaine.com",
    "bizprodigy.com",
    "boboswings.com",
    "bobowings.com",
    "bonafidedeals.com",
    "brandiasm.com",
    "broadbaymortgage.com",
    "broadbayrealty.com",
    "bubbaswings.com",
    "cajunhut.com",
    "candobrands.com",
    "candocrypto.com",
    "candocuts.com",
    "candodeals.com",
    "candodomains.com",
    "candographics.com",
    "candologos.com",
    "candowebsites.com",
    "caribhut.com",
    "caribxpress.com",
    "carnacdomains.com",
    "casinoante.com",
    "catchboxx.com",
    "catchgrill.com",
    "chilixpress.com",
    "christianproud.org",
    "clearviewpride.com",
    "collectrust.com",
    "comebackstronger.net",
    "comebackstronger.online",
    "comebackstronger.org",
    "comebackstronger.store",
    "comebackstronger.xyz",
    "cornerpawn.com",
    "courtesytrust.com",
    "crabcakes4u.com",
    "crazyoyster.com",
    "crispac.com",
    "delmarvaseafood.com",
    "determination.biz",
    "dearpoppop.com",
    "discoverscotch.com",
    "domaingourmet.com",
    "domainpoet.com",
    "downthecatch.com",
    "determinationwins.com",
    "digitaldaily.online",
    "diningdomains.com",
    "diningnames.com",
    "distinctionmagazine.com",
    "dock2box.com",
    "dock2u.com",
    "docktoyou.com",
    "dollydoesdallas.com",
    "dollydoesdallas.org",
    "dollyrocksdallas.com",
    "domainanimation.com",
    "domaincorruption.com",
    "domaindistinction.com",
    "domainfiesta.com",
    "domainideal.com",
    "domainsbygod.com",
    "domainswami.com",
    "domaintrivia.com",
    "domainxposure.com",
    "doobiecafe.com",
    "dramambassador.com",
    "equaty.com",
    "embracegolf.com",
    "everythingvb.com",
    "fajitahut.com",
    "famousfirstcars.com",
    "fanseafood.com",
    "fanseafresh.com",
    "fanseaseafood.com",
    "fantaseaseafood.com",
    "foodieambassador.com",
    "foodiedomains.com",
    "foodienames.com",
    "foodieshark.com",
    "foreverlease.com",
    "freshersbetter.com",
    "freshovernight.com",
    "freshovernite.com",
    "fruitlink.org",
    "ganjaxpress.com",
    "garagewings.com",
    "godmoments.org",
    "gofishdomains.com",
    "gofishgourmet.com",
    "gofishgrill.com",
    "gofishtaco.com",
    "golfclubstaffing.com",
    "golfstaffing.com",
    "gourmetcrabcakes.com",
    "gourmetgumbo.com",
    "gourmetoyster.com",
    "gourmetstaffers.com",
    "gourmetstaffing.com",
    "gumbogourmet.com",
    "hailtoabraham.com",
    "hailtoamerica.com",
    "hailtoliberty.com",
    "hailtofreedom.com",
    "hailtolife.com",
    "homecontractors.online",
    "hopetowne.com",
    "hugeapology.com",
    "ideas4money.com",
    "ifitstobeitsuptome.com",
    "iluveveryone.com",
    "innerfun.com",
    "imagigames.com",
    "itaintovertilitsover.com",
    "itpaystopartner.com",
    "jerkystuff.com",
    "jesusincharge.com",
    "joenames.com",
    "joesjob.org",
    "joesnames.com",
    "judgmentscore.com",
    "jumbolump.com",
    "jumbolumpdaddy.com",
    "karmaforsale.com",
    "karmaseafood.com",
    "kisspros.com",
    "knockdock.com",
    "knockknockpizza.com",
    "laughinn.com",
    "llust.com",
    "lamargasm.com",
    "leasingincome.com",
    "linkedgolf.org",
    "linkedtour.org",
    "linkgolf.org",
    "linksdomains.com",
    "linkthenew.com",
    "linktour.org",
    "lovenaming.com",
    "maknmoney.com",
    "markspodcast.com",
    "marksproshop.com",
    "mediterraneanyeah.com",
    "meetandfriend.com",
    "meetupministry.com",
    "meetupministry.org",
    "milliondollarbizidea.com",
    "monetizeyourdomain.com",
    "moxylady.com",
    "myguyjesus.com",
    "nameanimation.com",
    "namewerks.com",
    "nevercrat.com",
    "newgtld.domains",
    "nichedomaining.com",
    "occasionalone.com",
    "ofthinair.com",
    "oysterrocks.com",
    "paradetv.com",
    "parentsrule.org",
    "preventademic.com",
    "parentsrus.org",
    "passionsells.com",
    "pennysavior.org",
    "pettimeshare.com",
    "piscesgrill.com",
    "pizzatrivia.com",
    "play9.org",
    "poppopsdeli.com",
    "poppopspizza.com",
    "proirons.com",
    "puppytowne.com",
    "puppyvisit.com",
    "qualityjade.com",
    "qwickquote.com",
    "reelcatchy.com",
    "reelgourmet.com",
    "remarkablesex.com",
    "resortslink.com",
    "rewebsitedesign.com",
    "richfuel.com",
    "rickiesfashion.com",
    "roccodomains.com",
    "ruffruff.org",
    "rxmediterranean.com",
    "rxseafood.com",
    "savvyjew.com",
    "seabitch.com",
    "scoringdomains.com",
    "seekingbizpartner.com",
    "seriouscomplaint.com",
    "seriouscomplaints.com",
    "seriousdisputes.com",
    "shakerandroller.com",
    "showpatriotism.com",
    "spaceaxiom.com",
    "spectacularsex.com",
    "spiritualspanking.com",
    "squarley.com",
    "startup321.com",
    "surrendernever.com",
    "swamidomains.com",
    "swamitravel.com",
    "swinglikescott.com",
    "swotsports.com",
    "thunnusalbacares.com",
    "tossupsports.com",
    "townebuilders.com",
    "townedepot.com",
    "takeherback.com",
    "trickm.com",
    "tuxedopros.com",
    "tuxedostaffers.com",
    "tuxedostaffing.com",
    "tuxedovalet.com",
    "universalfoodie.com",
    "usunited.com",
    "varsitydomains.com",
    "varsitynames.com",
    "virtualspanking.com",
    "visitinghero.com",
    "visitingpuppy.com",
    "voterstrategist.com",
    "washingtonhogs.org",
    "welcome321.com",
    "whiskyfocus.com",
    "wordprodigy.com",
    "webenomics.com",
    "websitescoring.com",
    "weemore.com",
    "whiskeytrivia.com",
    "whiskytrivia.com",
    "winsomore.com",
    "xceptionaldomains.com",
    "xceptionalnames.com",
    "xnevertrump.com",
    "xnevertrumper.com",
    "yum21.com",
    "yumnames.com",
    "yumpros.com",
    "yankeegourmet.com",
    "zcraze.com",
    "zdevil.com",
  ];

  const handleDomainButtonClick = (domainName) => {
    const url = `https://www.godaddy.com/domainsearch/find?domainToCheck=${domainName}`;
    window.open(url, "_blank");
  };

  return (
    <div className="collection">
      <div className="collectiontitle">
        <h1>My Collection</h1>
      </div>
      <div className="domainnamescontainer">
        {domainNames.map((domainName, index) => (
          <button
            key={index}
            className="domainbutton"
            onClick={() => handleDomainButtonClick(domainName)}
          >
            {domainName}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Collection;
