import React from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./Components/Header/Header";
import Blog from "./Components/Blog/Blog";
import Contact from "./Components/Contact/Contact";
import About from "./Components/About/About";
import Homepage from "./Components/Homepage/Homepage";
import Collection from "./Components/Collection/Collection";
import "./App.css";

function App() {
  return (
    <div>
      <Header />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/collection" element={<Collection />} />
      </Routes>
    </div>
  );
}

export default App;
