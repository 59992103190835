import React from "react";
import "./Hero.css";
import { useNavigate } from "react-router-dom";

const Hero = () => {
  const navigate = useNavigate();

  return (
    <div className="hero">
      <div className="herotitle">
        <h1>
          Your One-Stop Destination for <br></br><div className="test">Premium Domain Names.</div>
        </h1>
        <div className="herocontent">
          <p>
            Discover a diverse selection of top-tier domains, already curated in
            our collection. Elevate your online presence with our premium domain
            solutions, tailored to meet your unique needs.
          </p>
        </div>
        <button
          className="collectionbutton"
          onClick={() => navigate("/collection")}
        >
          Browse Collection
        </button>
      </div>
    </div>
  );
};

export default Hero;
